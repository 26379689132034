.bg-image {
	background-image: url("https://images.unsplash.com/photo-1526353043579-c836f1c675ad?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80");
	filter: blur(4px);
	-webkit-filter: blur(4px);

	/* Full height */
	height: 100vh;

	/* Center and scale the image nicely */
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.react-calendar {
	border: 0;
	font-family: "GyeonggiBatang";
	/* color: #969696; */
	color: #666;
	border-radius: 8px;
	/* box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2); */
}

.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
	color: #222;
	font-weight: bold;
}
.react-calendar__month-view__weekdays {
	color: #222;
}

.react-calendar__month-view__days__day--weekend {
	color: #e88080;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
	background-color: #fff;
}
.react-calendar__tile--active {
	background: #ef9b83 !important;
	color: #fff;
	border-radius: 50%;
	font-weight: bold;
}

.react-calendar__tile {
	/* max-width: 100%; */
	padding: 13px 10px;
	/* background: none; */
	/* text-align: center;
    line-height: 16px; */
}

.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
	display: none;
}

.react-calendar__navigation__arrow.react-calendar__navigation__prev-button {
	display: none;
}

.react-calendar__navigation__arrow.react-calendar__navigation__next-button {
	display: none;
}

.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
	display: none;
}

.react-calendar__navigation__label {
	@apply bg-default dark:bg-[#222];
	/* background-color: #fff !important; */
}

.scroller {
	height: 100vh;
}

.orange {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 100vh;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #753500;
}

.text {
	color: #fff;
}

.black {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 100vh;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #070707;
}

.blue {
	height: 100vh;
	background-color: #00026d;
}

.text-wrap {
	position: relative;
	overflow: hidden;
	width: 450px;
	height: 80vh;
}

.panel-text {
	position: absolute;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: 0%;
	z-index: 1;
	width: 100%;
	height: 100%;
	font-size: 40px;
	text-transform: uppercase;
	font-weight: 900;
	text-align: center;
	background-color: #070707;

	transform: translateY(100%);
	opacity: 0;
}

.panel-text.blue-text {
	color: blue;
}

.panel-text.red-text {
	color: red;
}

.panel-text.purple-text {
	color: purple;
}

.panel-text.orange-text {
	color: orange;
}

.p-wrap {
	position: relative;
	overflow: hidden;
	width: 450px;
	height: 80vh;
}

.panel {
	position: absolute;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: 0%;
	z-index: 1;
	width: 100%;
	height: 100%;
}

.panel._2 {
	z-index: 1;
}

.panel.blue {
	z-index: auto;
}

.panel.red {
	z-index: auto;
	background-color: red;
	background-image: none;
}

.panel.orange {
	z-index: auto;
	background-color: #cf5d00;
	background-image: none;
}

.panel.purple {
	z-index: auto;
	background-color: #808;
	background-image: none;
}
