@import url("http://fonts.cdnfonts.com/css/plaster");
@font-face {
	font-family: "Happiness-Sans-Title";
	src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2205@1.0/Happiness-Sans-Title.woff2")
		format("woff2");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "GmarketSansMedium";
	src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansMedium.woff")
		format("woff");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Happiness-Sans-Bold";
	src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2205@1.0/Happiness-Sans-Bold.woff2")
		format("woff2");
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: "GyeonggiBatang";
	src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_one@1.0/GyeonggiBatang.woff")
		format("woff");
	font-weight: normal;
	font-style: normal;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	margin: 0;
	font-family: "GyeonggiBatang", "Happiness-Sans-Bold", "GmarketSansMedium",
		-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	--black: #000000;
	--ash-black: #222;
	--white: #fafafa;
	--sky: #00ccff;
	--green: #22dddd;
	--blue: #1300ff;
	--dusk: #6600ff;
	--purple: #9900ff;
	--pink: #ff0066;
	--red: #fe0222;
	--orange: #fd7702;
	--yellow: #ffbb00;
	--base: #eee;

	--background: var(--base);
	--accent: var(--white);
}

@keyframes blinking {
	from {
		color: #282c34;
	}
	to {
		color: transparent;
	}
}

@keyframes blinking-dark {
	from {
		color: #f92672;
	}
	to {
		color: transparent;
	}
}

.block {
	display: block;
}

.cursor {
	animation: blinking 0.8s infinite;
}
.cursor-dark {
	animation: blinking-dark 0.8s infinite;
}

footer {
	/* position: fixed; */
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 20px;
	background-image: radial-gradient(
		rgba(0, 0, 0, 0) 1px,
		var(--background) 1px
	);
	background-size: 4px 4px;
	backdrop-filter: blur(3px);
	-webkit-backdrop-filter: blur(3px);
	-moz-backdrop-filter: blur(3px);
	font-size: 14px;
	line-height: 14px;
}

footer::before {
	display: block;
	content: "";
	/* position: absolute; */
	top: -1px;
	left: 0;
	right: 0;
	height: 1px;
	background: var(--accent);
	opacity: 0.2;
}

footer svg {
	margin-right: 20px;
}

footer a {
	text-decoration: none;
	color: var(--accent);
}

code {
	font-family: input-mono, monospace;
	font-weight: 400;
	font-style: normal;
}

::-webkit-scrollbar {
	height: 5px;
	width: 5px;
	background: var(--background);
}

::-webkit-scrollbar-thumb {
	background: var(--accent);
	-webkit-border-radius: 1ex;
}

::-webkit-scrollbar-corner {
	background: var(--background);
}

section {
	padding-top: 82vh;
	/* padding-bottom: 85vh; */
	position: relative;
}

/* img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
} */

.progress {
	position: fixed;
	left: 0;
	right: 0;
	height: 5px;
	background: var(--accent);
	bottom: 100px;
}

.parallax {
	overflow: hidden;
	/* letter-spacing: -2px; */
	line-height: 0.8;
	margin: 0;
	white-space: nowrap;
	display: flex;
	flex-wrap: nowrap;
}

.parallax .scroller {
	font-weight: 600;
	text-transform: uppercase;
	font-size: 32px;
	display: flex;
	white-space: nowrap;
	display: flex;
	flex-wrap: nowrap;
	padding: 10px 0;
}

.parallax span {
	display: block;
	margin-right: 30px;
	font-family: "Plaster";
}

.background-dot {
	/* position: fixed; */
	bottom: 48px;
	left: 0;
	right: 0;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 30px;
	background-image: radial-gradient(
		rgba(0, 0, 0, 0) 1px,
		var(--background) 1px
	);
	background-size: 4px 4px;
	backdrop-filter: blur(3px);
	-webkit-backdrop-filter: blur(3px);
	-moz-backdrop-filter: blur(3px);
	font-size: 14px;
	line-height: 14px;
}

.background-dot::before {
	display: block;
	content: "";
	/* position: absolute; */
	top: -1px;
	left: 0;
	right: 0;
	height: 1px;
	background: var(--accent);
	opacity: 0.2;
}

.accordion-title {
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
}

.accordion-title.open {
	-webkit-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
}
